import React from 'react'
// <a href="#" target="_blank">Twitter</a>

export default function Footer () {
  return (
    <footer className="site-footer clearfix">
      <div className="footer-social">
        <ul className="footer-social-links">
          <li>
            <a href="https://github.com/talaikis" target="_blank" rel="noreferrer">GitHub</a>
          </li>

          <li>
            <a href="https://www.linkedin.com/in/talaikis/" target="_blank" rel="noreferrer">LinkedIn</a>
          </li>
          <li>
            <a href="https://medium.com/@talaikis" target="_blank" rel="noreferrer">Medium</a>
          </li>
        </ul>
      </div>
      <div className="footer-copyrights footer-social-links">
        <ul>
        <li>© <a href="https://talaikis.com">Talaikis</a>, {new Date(Date.now()).getFullYear()}</li>
        </ul>
      </div>
    </footer>
  )
}
