import React from 'react'

export default function hero () {
  return (
    <div className="row">
      <div className=" col-xs-12 col-sm-12">
        <div className="home-content">
          <div className="row flex-v-align">
            <div className="col-sm-12 col-md-5 col-lg-5">
              <div className="home-photo">
                <div className="hp-inner bg-image" alt="Software Engineer">
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-7 col-lg-7">
              <div className="home-text hp-left">
                <div className="owl-carousel text-rotation">
                  <div className="item">
                    <h4>Software Engineer</h4>
                  </div>
                </div>

                <h1>Tadas Talaikis</h1>
                <p>Tech Solutions for Startups from the idea to production</p>

                <div className="home-buttons">
                  <a href="./cv.pdf" target="_blank" className="btn btn-primary">Download CV</a>
                  <a href="mailto://info@nordlet.com" target="_self" className="btn btn-secondary">Contact</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
