import React from 'react'
import PropTypes from 'prop-types'

function Item ({ title, category, url, image }) {
  return (
    <figure className="item lbimage">
      <div className="portfolio-item-img">
        <img src={image} alt={title} title={title} />
        {/* eslint-disable-next-line */}
        { !!url && <a className="lightbox" title={title} href={url}></a> }
      </div>

      <i className="fa fa-image"></i>
      <h4 className="name">{ title }</h4>
      <span className="category">{ category }</span>
    </figure>
  )
}

Item.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string
}

export default function Portfolio () {
  return (
    <div id="primary" className="content-area">
      <div id="content" className="page-content site-content single-post" role="main">
        <div className="row">
          <div className=" col-xs-12 col-sm-12 ">
            <div id="portfolio_content_q" className="portfolio-content">
              <div className="portfolio-grid two-columns shuffle">
                <Item title="NordLet" category="mobile" url="https://nordlet.com" image="/static/images/nordlet.png" />
                <Item title="Weido" category="mobile" url="https://weido.lt" image="/static/images/weido.jpg" />
                <Item title="Shareline" category="web" url="https://shareline.co" image="/static/images/shareline.png" />
                <Item title="True Tesla Technologies" category="web" image="/static/images/ttt.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
