import React from 'react'
import PropTypes from 'prop-types'

export function BlockTitle ({ title, variant }) {
  if (variant === 1) {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <div className="block-title">
            <h2>{ title }</h2>
          </div>
        </div>
      </div>
    )
  }

  if (variant === 2) {
    return (
      <div className="block-title">
        <h2>{ title }</h2>
      </div>
    )
  }

  if (variant === 3) {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <div className="p-50"></div>

          <div className="block-title">
            <h2>{ title }</h2>
          </div>
        </div>
      </div>
    )
  }
}

BlockTitle.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.number
}

export default function FunFacts () {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-3">
        <div className="lm-info-block gray-default">
          <i className="linecons linecons-star"></i>
          <h4>Performance</h4>
          <span className="lm-info-block-value">Top 1%</span>
          <span className="lm-info-block-text"></span>
        </div>
      </div>

      <div className="col-xs-12 col-sm-3">
        <div className="lm-info-block gray-default">
          <i className="linecons linecons-clock"></i>
          <h4>Work Hours (2022)</h4>
          <span className="lm-info-block-value">1,708</span>
          <span className="lm-info-block-text"></span>
        </div>
      </div>

      <div className="col-xs-12 col-sm-3 ">
        <div className="lm-info-block gray-default">
          <i className="linecons linecons-heart"></i>
          <h4>Projects Currently</h4>
          <span className="lm-info-block-value">1</span>
          <span className="lm-info-block-text"></span>
        </div>
      </div>

      <div className="col-xs-12 col-sm-3 ">
        <div className="lm-info-block gray-default">
          <i className="linecons linecons-cup"></i>
          <h4>Portfolio Projects</h4>
          <span className="lm-info-block-value">50+</span>
          <span className="lm-info-block-text"></span>
        </div>
      </div>
    </div>
  )
}
