
import React from 'react'

export default function Services () {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-6">
        <div className="info-list-w-icon">
          <div className="info-block-w-icon">
            <div className="ci-icon">
              <i className="linecons linecons-pen"></i>
            </div>
            <div className="ci-text">
              <h4>Backend Services Development</h4>
              <p>Node.js or other languages for the job, serverless functions, monoliths, images, and bare metal servers.  Event-driven microservices infrastructure, SQL (Postgres,  CocroachDb, TSDB, etc.), or NoSQL (MongoDb, DynamoDb, etc.) databases.  AWS services.  TDD.</p>
            </div>
          </div>

        </div>
      </div>

      <div className="col-xs-12 col-sm-6">
        <div className="info-block-w-icon">
            <div className="ci-icon">
              <i className="linecons linecons-display"></i>
            </div>
            <div className="ci-text">
              <h4>Web / Mobile Development</h4>
              <p>Mobile (React Native) or web (ReactJs, NextJs) development. Figma to production. Component-based test-driven development. Mobile (React Native) or web (ReactJS, Next.js) development. Figma to production. Component-based test-driven development.</p>
            </div>
          </div>
      </div>
    </div>
  )
}
