import React from 'react'

import Meta from 'components/meta'
import Header from 'components/header'
import Hero from 'components/hero'
import Services from 'components/services'
// import Clients from 'components/clients'
import Portfolio from 'components/portfolio'
// import Pricing from 'components/pricing'
import FunFacts, { BlockTitle } from 'components/funFacts'
import Footer from 'components/footer'

export default function Home () {
  return (
    <>
      <Meta />
      {/* <div className="lm-animated-bg"></div>
      <div className="preloader">
        <div className="preloader-animation">
          <div className="preloader-spinner">
          </div>
        </div>
      </div>
      <div className="lmpixels-scroll-to-top"><i className="lnr lnr-chevron-up"></i></div>
       */}

      <div className="page-scroll">
        <div id="page_container" className="page-container bg-move-effect" data-animation="transition-flip-in-right">
          <Header />

          <div id="main" className="site-main">
            <div id="main-content" className="single-page-content">
              <div id="primary" className="content-area">    
                <div id="content" className="page-content site-content single-post" role="main">
                  <Hero />
                  <BlockTitle title="Services" variant={3}/>
                  <Services />

                  <div className="row">
                    <div className="col-xs-12 col-sm-12">
                      <div className="p-20"></div>
                      {/* <BlockTitle title="Testimonials" variant={2}/>
                      <Testimonials />
                      <div className="p-40"></div> */}

                      <BlockTitle title="Portfolio (2020+)" variant={2}/>
                      <Portfolio />
                      <div className="p-40"></div>

                      {/*
                      <BlockTitle title="Clients" variant={2}/>
                      <Clients />
                      <div className="p-40"></div>
                      */}
                    </div>
                  </div>

                  <BlockTitle title="Facts" variant={1} />
                  <FunFacts />
                  <div className="p-40"></div>
                  { /* <BlockTitle title="Pricing" variant={1} /> <Pricing /> */ }
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
