import React from 'react'
import Head from 'next/head'

export default function Meta () {
  return (
    <Head>
      <title>Software Engineer | Talaikis</title>
      <link rel="icon" href="/favicon.ico" />
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="description" content="Software Engineer" />
      <meta name="keywords" content="software engineering,software development,scrapping,quantitative finance,automated marketing,seo" />
      <meta name="author" content="Tadas Talaikis" />
    </Head>
  )
}
